<template>
  <div class="h-screen">
    <TabsWrapper class="py-10 px-5" @tab-changed="tabChanged">
      <Tabs title="General Summary">
        <LocationsTable
          :labels="['', 'Total', 'B2B', 'B2C']"
          :bottomArrows="false"
        >
          <template v-slot:table-content>
            <tr
              v-for="(metric, index) in metricsSummary"
              :key="index"
              class="bg-white p-5"
            >
              <td class="text-center">{{ metric.title }}</td>
              <td class="text-center">{{ metric.data.total }}</td>
              <td class="text-center">{{ metric.data.b2b }}</td>
              <td class="text-center">{{ metric.data.b2c }}</td>
            </tr>
          </template>
        </LocationsTable>
        <div v-if="isLoading" class="text-center mt-48">
          <em
            class="fa fa-spinner fa-spin text-white"
            style="font-size: 24px"
          ></em>
        </div>
      </Tabs>
      <Tabs title="User Subscriptions Summary">
        <LocationsTable
          :labels="[
            'Customer',
            'Type (B2B/B2C)',
            'Subscription ID',
            'Subscription Status',
            'Start Date',
            'Renewal Date',
            'Cancellation Date',
            'Subscription type',
            'Number of Accesses',
            'Coupon ID',
            'Coupon Duration',
            'Percent off',
            'Amount per access',
            'Total Amount Billed',
          ]"
          :bottomArrows="false"
        >
          <template v-slot:table-content>
            <tr
              v-for="(userSubscription, index) in userSubscriptionSummary"
              :key="index"
              class="bg-white p-5"
            >
              <td class="text-center">{{ userSubscription.customer_email }}</td>
              <td class="text-center">{{ userSubscription.user_type }}</td>
              <td class="text-center">
                {{ userSubscription.subscription_id }}
              </td>
              <td class="text-center">
                {{ userSubscription.subscription_status }}
              </td>
              <td class="text-center single-line-cell">
                {{ userSubscription.created_at }}
              </td>
              <td class="text-center single-line-cell">
                {{ userSubscription.ended_at }}
              </td>
              <td class="text-center single-line-cell">
                {{ userSubscription.canceled_at }}
              </td>
              <td class="text-center">
                {{ userSubscription.subscription_type }}
              </td>
              <td class="text-center">
                {{ userSubscription.number_of_accesses }}
              </td>
              <td class="text-center">{{ userSubscription.coupon_id }}</td>
              <td class="text-center">
                {{ userSubscription.coupon_duration }}
              </td>
              <td class="text-center">{{ userSubscription.percent_off }}</td>
              <td class="text-center single-line-cell">
                {{ userSubscription.access_amount }}
              </td>
              <td class="text-center single-line-cell">
                {{ userSubscription.total_amount_billed }}
              </td>
            </tr>
          </template>
        </LocationsTable>
        <div v-if="isLoading" class="text-center mt-48">
          <em
            class="fa fa-spinner fa-spin text-white"
            style="font-size: 24px"
          ></em>
        </div>
      </Tabs>
      <Tabs title="Organizations Summary">
        <LocationsTable
          :labels="['', 'Total', 'Active Locations', 'Canceled Locations']"
          :bottomArrows="false"
        >
          <template v-slot:table-content>
            <tr
              v-for="(organization, index) in organizationsSummary"
              :key="index"
              class="bg-white p-5"
            >
              <td class="text-center">{{ organization.title }}</td>
              <td class="text-center">{{ organization.data.total }}</td>
              <td class="text-center">
                {{ organization.data.active_locations }}
              </td>
              <td class="text-center">
                {{ organization.data.deactivated_locations }}
              </td>
            </tr>
          </template>
        </LocationsTable>
        <div v-if="isLoading" class="text-center mt-48">
          <em
            class="fa fa-spinner fa-spin text-white"
            style="font-size: 24px"
          ></em>
        </div>
      </Tabs>
      <Tabs title="Organization Users Summary">
        <LocationsTable
          :labels="[
            'Organization',
            'Total Users',
            'New users last month',
            'Users Canceled',
            'Monthly Revenue',
            'Semi-Annual Revenue',
            'Annual Revenue',
          ]"
          :bottomArrows="false"
        >
          <template v-slot:table-content>
            <tr
              v-for="(organization, index) in organizationUsersSummary"
              :key="index"
              class="bg-white p-5"
            >
              <td class="text-center">{{ organization.location_name }}</td>
              <td class="text-center">{{ organization.total_users }}</td>
              <td class="text-center">{{ organization.new_users }}</td>
              <td class="text-center">{{ organization.users_canceled }}</td>
              <td class="text-center">{{ organization.monthly_revenue }}</td>
              <td class="text-center">{{ organization.semianual_revenue }}</td>
              <td class="text-center">{{ organization.annual_revenue }}</td>
            </tr>
          </template>
        </LocationsTable>
        <div v-if="isLoading" class="text-center mt-48">
          <em
            class="fa fa-spinner fa-spin text-white"
            style="font-size: 24px"
          ></em>
        </div>
      </Tabs>
    </TabsWrapper>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import LocationsTable from "@/components/location/LocationsTable.vue";
import Tabs from "@/components/general/Tabs.vue";
import TabsWrapper from "@/components/general/TabsWrapper.vue";
import {
  getSubscriptionsSummary,
  getMonthlyRevenueSummary,
  getUserSubscriptionsSummary,
  getOrganizationsSummary,
  getOrganizationUsersSummary,
} from "@/services/stripe/stripe.js";

const metricsSummary = ref([]);
const userSubscriptionSummary = ref([]);
const organizationsSummary = ref([]);
const organizationUsersSummary = ref([]);
const isLoading = ref(false);
const activeTab = ref("General Summary");

onMounted(async () => {
  isLoading.value = true;
  try {
    const responses = await Promise.all([
      getSubscriptionsSummary(),
      getMonthlyRevenueSummary(),
    ]);

    metricsSummary.value = responses.flat();
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    isLoading.value = false;
  }
});

function tabChanged(newTabTitle) {
  activeTab.value = newTabTitle;
}

async function loadData(getDataFunction, targetRef) {
  if (targetRef.value.length === 0) {
    isLoading.value = true;
    try {
      const response = await getDataFunction();
      targetRef.value = response;
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      isLoading.value = false;
    }
  }
}

watch(activeTab, async (newTab) => {
  if (newTab === "User Subscriptions Summary") {
    await loadData(getUserSubscriptionsSummary, userSubscriptionSummary);
  } else if (newTab === "Organizations Summary") {
    await loadData(getOrganizationsSummary, organizationsSummary);
  } else if (newTab === "Organization Users Summary") {
    await loadData(getOrganizationUsersSummary, organizationUsersSummary);
  }
});
</script>

<style scoped>
select:focus .default-option {
  display: none;
}

img {
  border-radius: 50%;
  background-color: #edfe86;
  width: 52px;
  height: 52px;
}

#card {
  width: 30%;
}
table {
  border: none;
}

th {
  color: #1d2e48;
  background: #ecf0f5;
}

tr {
  border-top: 1px solid #ecf0f5;
  border-bottom: 2px solid #ecf0f5;
}

th,
td {
  border: none;
}

tr td {
  padding: 20px;
}

tr:hover td {
  background: #4e5066;
  color: #ffffff;
}

tr:first-child {
  border-top: none;
}

tr:last-child {
  border-bottom: none;
}

.single-line-cell,
.fixed-width-cell {
  min-width: 120px;
  white-space: nowrap;
}
</style>
